.join_subscription_confirmation_message_container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
    ". a a a ."
    ". . . . .";
    min-height: 80vh;
    margin-top: 2rem;
}

.join_subscription_confirmation_message{
    grid-area: a;
}

.join_subscription_confirmation_message a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

@media ( max-width: 480px ){

    .join_subscription_confirmation_message_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            "a a a a a"
            ". . . . .";
        margin: 2rem;
    }
    
}