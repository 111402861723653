.add_subscription{
    display: flex;
    flex-direction: row;
}

.add_subscription_left{
    flex: 4;
}

.add_subscription_right{
    flex: 1;
}

.add_subscription_plus{
    display: flex;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    color: rgb(2, 227, 81);
    font-size: 2rem;
}