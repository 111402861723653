.signin_container{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        ". . ."
        ". a ."
        ". . ."
        ". . .";
}

.signin {
    grid-area: a;
    display: flex;
    justify-content: center;
}

.signin a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.signin input {
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    border: none;
}

.signin h1, h2 {
    font-size: 2.5rem;
    text-align: center;
}

.sign_in_alternatives{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    grid-template-areas:
        "a_alt a_alt b_alt b_alt";
    font-size: medium;
    margin: 2rem 0rem;
}

.sign_in_alternatives a{
    font-size: 1rem;
}

.sign_in_forgot_password{
    grid-area: a_alt;
    text-align: left;
}

.sign_up_link {
    grid-area: b_alt;
    text-align: right;
}

.signin_error_message{
    color: #f00;
}

@media ( max-width: 480px ){

    .signin_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            ". . . . ."
            ". a a a ."
            ". . . . .";
    }
    
    .signin a {
        font-size: medium;
        margin: 0rem;
    }

    .signin input {
        padding: 0.5rem;
        margin: 0.8rem 0rem;
    }

    .signin h1, h2 {
        font-size: 2rem;
        text-align: center;
    }

    .signin h2 {
        font-size: 0.8rem;
        margin: 1rem;
    }

    .sign_in_alternatives{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas:
            "."
            "a_alt"
            "b_alt"
            ".";
        margin: 0.6rem 0rem;
    }

    .sign_up_link{
        grid-area: b_alt;
        max-height: 50%;
        margin: -6rem 0 -4rem 0;
        text-align: center;
    }

    .sign_up_link a{
        font-size: 0.8rem;
    }


    .sign_in_forgot_password{ 
        grid-area: a_alt;
        margin: -6rem 0 -4rem 0;
        text-align: center;
    }

    .sign_in_forgot_password a{
        font-size: 0.8rem;
    }

}