.see_how_container{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        ". . ."
        ". a ."
        ". . ."
        ". . .";
}

.see_how {
    grid-area: a;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.see_how a {
    font-size: x-large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.see_how h3 {
    margin: 1.5rem;
}

.see_how h1 {
    font-size: 2.5rem;
}



@media ( max-width: 480px ){

    .see_how_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        grid-template-areas:
            ". . ."
            ". a ."
            ". . .";
    }
    
    .see_how a {
        font-size: large;
    }

    .see_how h3 {
        margin: 1rem;
    }

}