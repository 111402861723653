.header_container{
    background-color: #fff;
    min-height: 10%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        ". a .";
    box-shadow: 0 0 25px #ccc;
}

.header {
    grid-area: a;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header a {
    font-size: large;
    color: #000;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.header input{
    background: none;
    border: none;
    font-size: large;
    color: #000;
}

.header h3 {
    margin: 1rem;
}

.header h1 {
    font-size: 2.5rem;
}



@media ( max-width: 480px ){

    .header_container{
        min-height: 10%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            "a a a a a";
    }
    
    .header a, input[type=button]{
        font-size: 0.8rem;
        font-weight: 600;
    }

    .header h3 {
        margin: 1rem;
    }

}