.add_subscription_review_container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
    ". a a a ."
    ". . . . .";
    min-height: 80vh;
    margin-top: 2rem;
}

.add_subscription_review{
    grid-area: a;
}

.add_subscription_review a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.add_subscription_review input {
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    border: 2px solid #000;
}

.add_subscription_review input[type=submit]{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}


@media ( max-width: 480px ){

    .add_subscription_review_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            "a a a a a"
            ". . . . .";
        margin: 2rem;
    }
    
}