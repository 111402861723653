.join_subscription{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 10px;
    grid-template-areas:
    "join_subscription  charge           chat"
    "join_subscription password         payment_method"
    "payout            reviews          schedule"
}

.subscriber_profile{
    grid-area: subscriber_profile;
}

.join_subscription{
    display: flex;
    flex-direction: row;
}

.join_subscription_left{
    flex: 4;
}

.join_subscription_right{
    flex: 1;
}

.join_subscription_plus{
    display: flex;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    color: rgb(2, 227, 81);
    font-size: 1.5rem;
}