.signup_container{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
        ". . . . ."
        ". . a . ."
        ". . . . ."
}

.signup {
    grid-area: a;
    display: flex;
    justify-content: center;
}

.signup a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.signup input {
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    border: 2px solid #000;
}


.signup input[type=submit]{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}

.signup h1, h2 {
    font-size: 2.5rem;
    text-align: center;
}

.sign_in_link {
    font-size: medium;
    margin: 2rem 0rem;
    text-align: center;
}

.sign_in_link a{
    font-size: 1rem;
}

.signup_error_message{
    color: #f00;
}

.signup_error_message button{
    background: none;
    border: none;
    color: #000;
    text-decoration: underline;
}


.loader {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #888;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
      
      
.googleButton {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-grow: 1; /* Add this property to make the button stretch */
  }

.facebookButton {
    width: 100%;
    background-color: #4267b2;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}
      
.appleButton {
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}

/* .googleIcon {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;\
  margin-right: 8px;
} */
      
.facebookIcon {
  margin-right: 8px;
}

.appleIcon {
  margin-right: 8px;
}

@media ( max-width: 480px ){

    .signup_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            ". . . . ."
            ". a a a ."
            ". . . . .";
    }
    
    .signup a {
        font-size: medium;
        margin: 0rem;
    }

    .signup input {
        padding: 0.5rem;
        margin: 0.8rem 0rem;
    }

    .signup h1, h2 {
        font-size: 2rem;
        text-align: center;
    }

    .signup h2 {
        font-size: 0.8rem;
        margin: 1rem;
    }

    .sign_in_link{
        width: 100%;
        text-align: center;
    }

    .sign_in_link a{
        font-size: 0.8rem;
    }

}