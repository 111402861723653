.add_subscription_form_add_debit_card{
    height: 0px;
    overflow: hidden;
}

.add_subscription_form_container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
    ". . a . ."
    ". . . . .";
    min-height: 80vh;
    margin-top: 2rem;
}

.add_subscription_form{
    grid-area: a;
}

.add_subscription_form a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.add_subscription_form input {
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    border: 2px solid #000;
}

.add_subscription_form input[type=submit]{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}

.add_subscription_form select{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}

.add_subscription_form h1, h2 {
    font-size: 2rem;
    text-align: center;
}

.add_subscription_form_info{
    background-color: #444;
    border: 1px solid #000;
    padding: 0.5rem;
    color: #fff;
}

@media ( max-width: 480px ){

    .add_subscription_form_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            "a a a a a"
            ". . . . .";
        margin: 2rem;
    }
    
    .add_subscription_form a {
        font-size: medium;
        margin: 0rem;
    }

    .add_subscription_form input {
        padding: 0.5rem;
        margin: 0.8rem 0rem;
    }

    .add_subscription_form h1, h2 {
        font-size: 2rem;
        text-align: center;
    }

    .add_subscription_form h2 {
        font-size: 0.8rem;
        margin: 1rem;
    }
}