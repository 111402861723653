ul{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.toggle_voice_messages{
    display: flex;
    justify-content: center;
    align-items: center;
}

.voice_messages_menu{
    position: relative;
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.voice_messages_menu li{
    position: absolute;
    left: 0;
    list-style: none;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;
    color: var(--clr);
    transition: 0.5s;
    transform-origin: 140px;
    transform: rotate(0degs) translate(110px);
}

.voice_messages_menu .toggle_voice_messages{
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: #2f362e;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100000;
    font-size: 2em;
    transition: transform 1.25s;
}


.voice_messages_menu.active .toggle_center{
    transform: rotate(315deg);
}
    
.voice_messages_menu.active li{
    transform: rotate(calc(360deg / 8 * var(--i))) translateX(0deg);
    color: var(--clr);
}

.watch_party_container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
        ". watch_party watch_party watch_party ."
        ". watch_party watch_party watch_party ."
        ". . . . .";
}

.watch_party{
    grid-area: watch_party;
}

.watch_party_interact_with_show{
    min-height: 28vh;
}

.watch_party_talk{
    /* max-width: 100%; */
    flex: 1;
    height: 55vh;
    overflow-y: scroll;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    /* overflow-x: scroll; */
}

.watch_party_talk input {
    max-width: 70%;
    /* max-height: 0.8rem; */
    background: rgba(255, 255, 255, 0.14);
    border-radius: 4px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.1px);
    -webkit-backdrop-filter: blur(1.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* color: #fff; */
    padding: 0.4rem;
}

.watch_party_comment_input input{
    /* height: 50px; */
    border: 1px solid #000;
}

.watch_party_host{
    min-height: 5vh;
}

.watch_party_interact_container input{
    /* max-width: 50%; */
    background-color: #444;
    border: none;
    padding: 0.2rem;
    border-radius: 2px;
}

.watch_party_interact_add_character{
    height: 0px;
    overflow: hidden;
}

.watch_party_interact_add_scene{
    height: 0px;
    overflow: hidden;
}

.watch_party_interact_add_whatever{
    height: 0px;
    overflow: hidden;
}

.watch_party_start_recording_audio_button{
    border-radius: 10px;
    margin: 0px auto;
}

.watch_party_start_recording_audio_button, .watch_party_stop_recording_audio_button, .watch_party_stop_recording_audio_try_again_button{
    width: fit-content;
    padding: 1rem;
    border: 1px solid #fff;
}

.watch_party_start_recording_audio_button:hover, .watch_party_stop_recording_audio_button:hover{
    background-color: #222;
}

.watch_party_audio_recodring_options{
    height: 0px;
    overflow: hidden;
}

.watch_party_stop_recording_audio_button{
    height: 0px;
    overflow: hidden;
    padding: 0%;
    border: 0px solid #fff;
}


.watch_party_stop_recording_audio_try_again_button{
    
}

.watch_party_conversations{
    max-width: 85%;
    display: flex;
    flex-direction: column;
}

.watch_party_start_recording_audio_button, .watch_party_stop_recording_audio_button, .watch_party_stop_recording_audio_try_again_button{
    width: fit-content;
    padding: 1rem;
    border: 1px solid #fff;
}

.watch_party_start_recording_audio_button:hover, .watch_party_stop_recording_audio_button:hover{
    background-color: #222;
}

.watch_party_audio_recodring_options{
    height: 0px;
    overflow: hidden;
}

.watch_party_stop_recording_audio_button{
    height: 0px;
    overflow: hidden;
    padding: 0%;
    border: 0px solid #fff;
}


.watch_party_stop_recording_audio_try_again_button{
    
}

.watch_party_conversations{
    max-width: 85%;
    display: flex;
    flex-direction: column;
}


@media ( max-width: 480px ){
    .watch_party_container{
        grid-template-columns: repeat(1, 1fr);
        /* gap: 40px; */
        grid-template-areas:
            "watch_party"
            "watch_party"
            ".";
    }

    .watch_party input{
        width: 100%;
        margin: 0.4rem;
    }

    .watch_party h1{
        font-size: 1rem;
    }

    .watch_party_interact_with_show{
        min-height: 35vh;
    }
    
    .watch_party_talk{
        flex: 1;
        height: 70vh;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }

    .watch_party_interact_with_show_add{
        display: flex;
        flex-direction: row;
    }
}