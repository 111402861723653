.join_subscription{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
    ". a a a ."
    ". . . . .";
    min-height: 80vh;
    margin-top: 2rem;
}

.subscriptions{
    grid-area: a;
}

.subscription{
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    cursor: pointer;
}

@media ( max-width: 480px ){

    .join_subscription{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            "a a a a a"
            ". . . . .";
        margin: 2rem;
    }
    
}