.footer_container{
    background-color: #000;
    min-height: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        ". . ."
        ". a ."
        ". . ."
        ". . .";
}

.footer {
    grid-area: a;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer a {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.footer h3 {
    margin: 1.5rem;
}

.footer h1 {
    font-size: 2.5rem;
}



@media ( max-width: 480px ){

    .footer_container{
        min-height: 50%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        grid-template-areas:
            ". . ."
            ". a ."
            ". . .";
    }
    
    .footer a {
        font-size: 0.8rem;
    }

    .footer h3 {
        margin: 1rem;
    }

}