.watch_party_comment{
    color: #fff;
    width: fit-content;
    padding: 0.2rem;
    margin: 0.4rem;
    border-radius: 0.4rem;
}

@media ( max-width: 480px ){
    .watch_party_comment{
        padding: 0.4rem;
    }
    
}