.send_code_container{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-template-areas:
        ". a a ."
        ". a a ."
        ". . . .";
}

.send_code {
    grid-area: a;
    align-self: center;
    justify-self: center;
}

.send_code button{
    margin: 1rem 0;
}

.verification_code {
    display: flex;
    flex-direction: row;
}
    
.verification_code input {
    background-color: #444;
    width: 25px;
    height: 35px;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    padding: 0.4rem;
    margin: 0.2rem;
    border-radius: 0.5rem;
    align-self: center;
    scroll-behavior: none;
    border: none;
}
