.dashboard_container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 20px;
    grid-template-areas:
    "dashboard";
    min-height: 80vh;
    margin-top: 2rem;
}

.dashboard{
    grid-area: dashboard;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
    ". add_subscription join_subscription payout ."
    ". add_subscription join_subscription reviews ."
    ". published_subscriptions published_subscriptions published_subscriptions ." 
    ". joined_subscriptions joined_subscriptions joined_subscriptions ." 
    ". strikes strikes payment_method ."
    ". . . . .";
    min-height: 80vh;
}

.dashboard > div{
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.1px);
    -webkit-backdrop-filter: blur(1.1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 1rem;
    margin: 1.5rem 0rem;
}

.dashboard > div h1{
    font-size: 1.5rem;
}

.whos_watching {
    grid-area: whos_watching;
}

.popular_watch_parties {
    grid-area: popular_watch_parties;
}

.your_watch_parties {
    grid-area: your_watch_parties;
}

.upcoming_watch_parties{
    grid-area: upcoming_watch_parties;
}

.create_watch_parties {
    grid-area: create_watch_parties;
}

.find_show_parties {
    grid-area: find_show_parties;
}

.published_subscriptions {
    grid-area: published_subscriptions;
}

.joined_subscriptions {
    grid-area: joined_subscriptions;
}

.add_subscription {
    grid-area: add_subscription;
}

.charge {
    grid-area: charge;
}

.chat {
    grid-area: chat;
}

.join_subscription {
    grid-area: join_subscription;
}

.password {
    grid-area: password;
}

.payment_method {
    grid-area: payment_method;
}

.payout {
    grid-area: payout;
}

.reviews {
    grid-area: reviews;
}

.schedule {
    grid-area: schedule;
}

.strikes {
    grid-area: strikes;
}

.footer {
    grid-area: footer;
}


@media ( max-width: 480px ){
    .dashboard_container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        grid-template-areas:
        "dashboard";
        margin-top: 1rem;
    }

    .dashboard{
        /* background-color: aquamarine; */
        max-width: 100%;
        grid-area: dashboard;
        min-height: 100%;
        /* max-width: 80px; */
        /* display: block; */
        /* width: auto; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        grid-template-areas:   
            "add_subscription"
            "join_subscription"
            "published_subscriptions"
            "joined_subscriptions"
            "payment_method"
            "payout"
            "reviews"
            "strikes";
    }
    
    .dashboard > div{
        border-radius: 4px;
        margin: 2rem 1rem;
        overflow: hidden;
    }

    .dashboard > div h1{
        font-size: 1rem;
    }

    .dashboard a {
        font-size: 0.8rem;
        margin: 0rem;
    }

    .dashboard input {
        padding: 0.5rem;
        margin: 0.8rem 0rem;
    }

    .dashboard h2 {
        font-size: 0.8rem;
        margin: 1rem;
    }

}