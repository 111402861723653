.landing_container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 20px;
    grid-template-areas:
    "landing";
    min-height: 100vh;
    margin-top: 2rem;
}

.landing{
    grid-area: landing;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
        ". . . . ."
        ". a a a ."
        ". . . . ."
        ". b b b ."
        ". . . . .";
}

.landing_one {
    grid-area: a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
}

.landing_one a {
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.landing_one h1 {
    font-size: 4rem;
    font-weight: 500;
}

.landing_one h3 {
    color: #444;
    font-weight: normal;
}

.landing_heading{
    display: flex;
    flex-direction: column;
}

.landing_signin_signup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.landing_signup{
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    margin: 0;
}

.landing_signin_signup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.landing_signup{
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    margin: 0 1rem;
}

.landing_two{
    grid-area: b;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
    min-height: 60vh;
}

.landing_two div{
    /* margin: 0 auto; */
    padding: 2rem;
}

.value_proposition{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* text-align: center; */
    min-height: 40vh;
}

.six_hundred_dollars{
    font-size: 2rem;
    font-weight: bold;
}

/* /// */
.landing-page {
    text-align: center;
    padding: 50px;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
  
  .step {
    width: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .step:hover {
    transform: translateY(-5px);
  }
  
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #666;
  }
  

@media ( max-width: 480px ){

    .landing{
        min-height: 100vh;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
        grid-template-areas:
            ". . . . . . ."
            ". . . . . . ."
            ". a a a a a ."
            ". . . . . . ."
            ". . . . . . ."
            "b b b b b b b";
    }
    
    .landing a {
        font-size: 1rem;
    }

    .landing h1 {
        font-size: 1.5rem;
    }

    .landing h3 {
        margin: 1rem;
    }

}