.add_subscription_form_add_debit_card{
    height: 0px;
    overflow: hidden;
}

.add_subscription_form_container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: 2fr, 3fr, 2fr; */
    gap: 40px;
    grid-template-areas:
    ". a a a ."
    ". . . . .";
    min-height: 80vh;
    margin-top: 2rem;
}

.add_subscription_form{
    grid-area: a;
}

.add_subscription_form a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.add_subscription_form input {
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    border: 2px solid #000;
}

.add_subscription_form input[type=submit]{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}

.add_subscription_form select{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}

.add_subscription_form h1, h2 {
    font-size: 2rem;
    text-align: center;
}

.add_subscription_form_info{
    background-color: #444;
    border: 1px solid #000;
    padding: 0.5rem;
    color: #fff;
}

label {
    padding-bottom: 5px;
    font-size: 13px;
}

input {
    margin-top: 3px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
}


/**/
.card {
    padding: 30px 25px 35px 50px;
    border-radius: 30px;
    box-shadow: 0px 4px 8px 0px #B71C1C;
    margin-top: 50px;
    margin-bottom: 50px
    }
    .border-line {
    border-right: 1px solid #BDBDBD
    }
    .text-sm {
    font-size: 13px
    }
    .text-md {
    font-size: 18px
    }
    .image {
    width: 60px;
    height: 30px
    }
    ::placeholder {
    color: grey;
    opacity: 1
    }
    :-ms-input-placeholder {
    color: grey
    }
    ::-ms-input-placeholder {
    color: grey
    }
    input {
    padding: 2px 0px;
    border: none;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 5px;
    margin-top: 2px;
    box-sizing: border-box;
    color: #000;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500
    }
    input:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #EF5350;
    outline-width: 0
    }
    button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
    }
    .btn-red {
    background-color: #EF5350;
    color: #fff;
    padding: 8px 25px;
    border-radius: 50px;
    font-size: 18px;
    letter-spacing: 2px;
    border: 2px solid #fff
    }
    .btn-red:hover {
    box-shadow: 0 0 0 2px #EF5350
    }
    .btn-red:focus {
    box-shadow: 0 0 0 2px #EF5350 !important
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #EF5350
    }
    @media screen and (max-width: 575px) {
    .border-line {
    border-right: none;
    border-bottom: 1px solid #EEEEEE
    }
    }

.join_subscription_add_new_payment_method{
    height: 0px;
    overflow: hidden;
}

input[type=radio]{
    /* border: none; */
    width: auto;
    height: auto;
}

@media ( max-width: 480px ){

    .add_subscription_form_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            "a a a a a"
            ". . . . .";
        margin: 2rem;
    }
    
    .add_subscription_form a {
        font-size: medium;
        margin: 0rem;
    }

    .add_subscription_form input {
        padding: 0.5rem;
        margin: 0.8rem 0rem;
    }

    .add_subscription_form h1, h2 {
        font-size: 2rem;
        text-align: center;
    }

    .add_subscription_form h2 {
        font-size: 0.8rem;
        margin: 1rem;
    }
}