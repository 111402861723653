.signin_container{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        ". . ."
        ". a ."
        ". . ."
        ". . .";
}

.signin {
    grid-area: a;
    display: flex;
    justify-content: center;
}

.signin a {
    font-size: large;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.signin input, button{
    background-color: #fff;
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    border: 2px solid #000;
}

.signin h1, h2 {
    font-size: 2.5rem;
    text-align: center;
}

.signin input[type=submit], button[type=submit]{
    background: none;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.8rem;
    color: #000;
}

.sign_in_alternatives{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    grid-template-areas:
        "a_alt a_alt b_alt b_alt";
    font-size: medium;
    margin: 2rem 0rem;
}

.sign_in_alternatives a{
    font-size: 1rem;
}

.sign_in_forgot_password{
    grid-area: a_alt;
    text-align: left;
}

.sign_up_link {
    grid-area: b_alt;
    text-align: right;
}

.signin_error_message{
    color: #f00;
}

/* ///// */
.signIn_button_wrapper_container {
    /* display: flex;
    flex-direction: column;
    align-items: stretch; */
}
      
.buttonWrapper {
    /* margin-bottom: 0.5rem; */
}


/* Override the default background color */

.loader {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #888;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
      
      
.googleButton {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-grow: 1; /* Add this property to make the button stretch */
  }

.facebookButton {
    width: 100%;
    background-color: #4267b2;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}
      
.appleButton {
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}

/* .googleIcon {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;\
  margin-right: 8px;
} */
      
.facebookIcon {
  margin-right: 8px;
}

.appleIcon {
  margin-right: 8px;
}
      
@media ( max-width: 480px ){

    .signin_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        grid-template-areas:
            ". . . . ."
            ". a a a ."
            ". . . . .";
    }
    
    .signin a {
        font-size: medium;
        margin: 0rem;
    }

    .signin input{
        padding: 0.5rem;
        margin: 0.8rem 0rem;
    }

    .signin h1, h2 {
        font-size: 2rem;
        text-align: center;
    }

    .signin h2 {
        font-size: 0.8rem;
        margin: 1rem;
    }

    .sign_in_alternatives{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas:
            "."
            "a_alt"
            "b_alt"
            ".";
        margin: 0.6rem 0rem;
    }

    .sign_up_link{
        grid-area: b_alt;
        max-height: 50%;
        margin: -6rem 0 -4rem 0;
        text-align: center;
    }

    .sign_up_link a{
        font-size: 0.8rem;
    }


    .sign_in_forgot_password{ 
        grid-area: a_alt;
        margin: -6rem 0 -4rem 0;
        text-align: center;
    }

    .sign_in_forgot_password a{
        font-size: 0.8rem;
    }

}