.policies_container{
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
        ". a a a .";
    margin-bottom: 4rem;
}

.policies {
    grid-area: a;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.policies p {
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.policies h3 {
    margin: 1.5rem 0;
    text-align: center;
}

.policies h1 {
    margin: 1.5rem 0;
    text-align: center;
}



@media ( max-width: 480px ){

    .policies_container{
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        grid-template-areas:
            "a";
    }
    
    .policies p {
        font-size: 1rem;
    }

    .policies h3 {
        margin: 1rem;
    }

}